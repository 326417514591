import styled from 'styled-components';

import { ILoaderDivProps } from './types';

export const LoaderDiv = styled.div<ILoaderDivProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background};
  z-index: ${(props) => props.zIndex};
  height: ${(props) => props.heightOverride};
`;

export const LoaderImg = styled.img`
  height: 20vh;
`;
